import { SANTA_MEMBERS_APP_ID, MEMBER_INFO_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';

export const isMemberAreaInstalled = wixCodeApi =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MEMBER_INFO_APP_ID,
      sectionId: 'member_info',
    }),
  );

export const setCommunitiesContext = () => async (dispatch, getState, { wixCodeApi }) => {
  const memberAreaInstalled = await isMemberAreaInstalled(wixCodeApi);

  dispatch({
    type: SET_COMMUNITIES_CONTEXT,
    payload: {
      [SANTA_MEMBERS_APP_ID]: {
        isInstalled: memberAreaInstalled,
      },
    },
  });
};
