import { createAction, urijs } from '@wix/communities-blog-client-common';
import { getInstance, getIsDebug } from '../../controller/helpers';
import { getCurrentPage } from '../../services/get-current-page';
import { isRtlLanguage } from '../../services/is-rtl-language';
import { resolveDeviceType } from '../../services/device-type';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';

export const SET_BASIC_PARAMS = 'SET_BASIC_PARAMS';
export const setBasicParamsAction = createAction(SET_BASIC_PARAMS);

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const setViewMode = createAction(SET_VIEW_MODE);

export const setBasicParams = ({ viewMode, language, biPageNumber }) => async (
  dispatch,
  getState,
  { wixCodeApi, appParams },
) =>
  dispatch(
    setBasicParamsAction(
      await resolveBasicParams({
        viewMode,
        language,
        appParams,
        biPageNumber,
        wixCodeApi,
        getState,
      }),
    ),
  );

export const resolveBasicParams = async ({ viewMode, language, appParams, biPageNumber, wixCodeApi, getState }) => {
  const showMobileView = wixCodeApi.location.url && urijs(wixCodeApi.location.url).hasQuery('showMobileView', 'true');
  const isCreatedWithResponsiveEditor = getIsCreatedWithResponsiveEditor(getState());
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const isRTL = isRtlLanguage(language);
  let currentPage = wixCodeApi.site.currentPage;
  if (viewMode.toLowerCase() !== 'site' && !isSSR) {
    currentPage = await getCurrentPage();
    currentPage.name = currentPage.title;
  }

  const apiBaseUrl = isSSR ? appParams.baseUrls.apiBaseUrl : appParams.baseUrls.apiBaseUrlClient;
  return {
    viewMode,
    instance: getInstance(wixCodeApi)(),
    formFactor: showMobileView ? 'Mobile' : resolveDeviceType(wixCodeApi, isCreatedWithResponsiveEditor),
    isSSR,
    isRTL,
    isSeo: wixCodeApi.seo.isInSEO(),
    language,
    apiBaseUrl,
    isDebug: getIsDebug(wixCodeApi),
    isProduction: process.env.NODE_ENV === 'production',
    biPageNumber,
    page: wixCodeApi.location.query.page,
    url: wixCodeApi.location.url,
    currentPage,
  };
};
