export {
  initializeStoreBaseData,
  initUserDependentStoreBaseData,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

import {
  initializeActions as initializeCommonActions,
  initializePromisifiedActions as initializeCommonPromisifiedActions,
} from '../../common/controller/init-actions';
import { setIsLoaded } from '../../common/store/is-loaded/is-loaded-actions';
import { tagClicked } from '../../common/actions/tag-clicked';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';
import { cleanPostEditorState, updatePostEditorPost } from '../../common/store/post-editor/post-editor-actions';
import { postPageResized } from '../actions/post-page-resize';
import { bindActionCreators } from 'redux';
import { biPostScrolled, biActiveTabChanged } from '../actions/bi';
import { emitOpenPost, emitClosePost } from '../actions/post-socket';

export function initializeActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }) {
  return {
    ...initializeCommonActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }),
    ...bindActionCreators(
      {
        fetchRecentPosts,
        fetchRelatedPosts,
        setIsLoaded,
        cleanPostEditorState,
        updatePostEditorPost,
        tagClicked,
        postPageResized,
        biPostScrolled,
        biActiveTabChanged,
        emitOpenPost,
        emitClosePost,
      },
      store.dispatch,
    ),
  };
}

export function initializePromisifiedActions({ store }) {
  return initializeCommonPromisifiedActions({ store });
}
