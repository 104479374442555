const LAZY_ACTIONS_BUNDLE_NAME = 'lazy-actions';

let lazyActions;
let _config = {};

export const initLazyActions = (config = {}) => (_config = config);

const loadActions = async () => {
  if (_config.isPreview || _config.isEditor) {
    lazyActions = (await import(/* webpackChunkName: "lazy-actions-editor" */ '../../viewer-script/lazy-actions'))
      .default;
  } else {
    importScripts(_config.staticsBaseUrl + `./${LAZY_ACTIONS_BUNDLE_NAME}.bundle.min.js`);
    lazyActions = global[LAZY_ACTIONS_BUNDLE_NAME].default;
  }
};

const invokeAction = name => (...args) => async dispatch => {
  if (!lazyActions && !_config.isSSR) {
    await loadActions();
  }

  return lazyActions[name] ? dispatch(lazyActions[name](...args)) : fallback(name);
};

function fallback(name) {
  if (process.env.NODE_ENV === 'development') {
    throw Error(`"${name}" action does not exist`);
  }
}

export const uploadImagePromisified = invokeAction('uploadImagePromisified');
export const saveAndPublishPostPromisified = invokeAction('saveAndPublishPostPromisified');
export const fetchOembedPromisified = invokeAction('fetchOembedPromisified');
export const addTextColor = invokeAction('addTextColor');
export const addCategory = invokeAction('addCategory');
export const createCategoryFromLabelPromisified = invokeAction('createCategoryFromLabelPromisified');
export const createCommentPromisified = invokeAction('createCommentPromisified');
export const incrementCommentLikeCount = invokeAction('incrementCommentLikeCount');
export const incrementPostLikeCount = invokeAction('incrementPostLikeCount');
export const updateCommentPromisified = invokeAction('updateCommentPromisified');
export const deleteComment = invokeAction('deleteComment');
export const createPostDraftPromisified = invokeAction('createPostDraftPromisified');
export const publishPostPromisified = invokeAction('publishPostPromisified');
export const savePostDraftPromisified = invokeAction('savePostDraftPromisified');
export const openPostSettings = invokeAction('openPostSettings');
export const subscribePromisified = invokeAction('subscribePromisified');
export const unsubscribePromisified = invokeAction('unsubscribePromisified');
export const unpinPostPromisified = invokeAction('unpinPostPromisified');
export const pinPostPromisified = invokeAction('pinPostPromisified');
export const disablePostCommentsPromisified = invokeAction('disablePostCommentsPromisified');
export const enablePostCommentsPromisified = invokeAction('enablePostCommentsPromisified');
export const requestFileUploadUrlPromisified = invokeAction('requestFileUploadUrlPromisified');
export const requestFileDownloadUrlPromisified = invokeAction('requestFileDownloadUrlPromisified');
export const search = invokeAction('search');
export const clearSearchResults = invokeAction('clearSearchResults');
