import { get } from 'lodash';
import { UPDATE_COMMENT_SUCCESS } from '../../../common/store/comments/update-comment-types';
import { DELETE_COMMENT_SUCCESS } from '../../../common/store/comments/delete-comment-types';
import {
  COMMENT_UPDATE,
  COMMENT_DELETE,
  POST_PUBLISH,
  POST_UPDATE,
} from '../../../common/components/message/message-types';
import { PUBLISH_POST_SUCCESS } from '../../../common/actions/publish-post-types';

export default {
  [UPDATE_COMMENT_SUCCESS]: () => COMMENT_UPDATE,
  [DELETE_COMMENT_SUCCESS]: () => COMMENT_DELETE,
  [PUBLISH_POST_SUCCESS]: action => (get(action, 'meta.isUpdated') ? POST_UPDATE : POST_PUBLISH),
};
