import { get } from 'lodash';
import { Router } from '../../common/router';
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_CATEGORY,
  ROUTE_CATEGORIES,
  ROUTE_SEARCH,
  ROUTE_LOGIN,
  ROUTE_ARCHIVE,
  ROUTE_TAGS,
} from '../constants/routes';
import {
  SECTION_HOMEPAGE,
  IS_BANNER_ENABLED_PATH,
  SECTION_CATEGORY,
  isExperimentEnabled,
  getAppConfig,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_TAGS_PAGE_SEO } from '@wix/communities-blog-experiments';
import fetchFeedPosts, { completeFetchFeedPosts } from '../../common/actions/fetch-feed-posts';
import fetchArchive from '../../common/actions/fetch-archive';
import {
  getIsValidPage,
  getShowPagination,
  getPageSize as getPageSizeFromSettings,
} from '../../common/selectors/pagination-selectors';
import { isExcludePostContentSupported } from '../../common/selectors/layout-selectors';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { fetchLastFeaturedPost, completeFetchLastFeaturedPost } from '../../common/actions/fetch-last-featured-post';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { search, clearSearchResults } from '../../common/controller/lazy-actions';
import { MIN_QUERY_LENGTH } from '../../search/actions/search-types';
import { fetchTag } from '../../common/actions/fetch-tag';
import { decodeQuery } from '../../search/services/query-encoding';
import { resolveCategorySlug } from '../../common/services/slug';
import { fetchCategoryPostsBySlug, completeFetchCategoryPosts } from '../../common/actions/fetch-category-posts';
import { fetchTagPosts } from '../../common/actions/fetch-tag-posts';
import {
  isSSR,
  getIsMobile,
  isEditor,
  getLanguage,
  isSite,
} from '../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../common/actions/page-opened';
import {
  FEED_PAGE,
  SEARCH_RESULTS,
  CATEGORY_PAGE,
  ARCHIVE_PAGE,
  FEED_PAGE_NOT_FOUND,
  TAGS_PAGE,
} from '../../common/services/detect-route';
import {
  generateFeedSEOTags,
  generateCategorySEOTags,
  generateTagSEOTags,
} from '../../common/services/generate-seo-tags';
import { getTagUrl } from '../../common/services/get-tag-url';
import { getPostsByCategoryIdAndPage } from '../../common/selectors/post-selectors';
import { getCategoriesMap, getCategoryBySlug } from '../../common/selectors/categories-selectors';
import { getCategorySchema } from '../../common/services/json-schema';
import { initI18N, translate } from '../../common/services/controller-translate';
import controllerTranslations from '../../common/controller-translations.json';
import { getSchemaEnabled } from '../../common/selectors/app-data-selectors';
import { setMetaTagRobotsNoIndex } from '../../common/services/set-metatag-robots-noindex';
import { setFeedSEOTags } from '../actions/set-feed-seo-tags';
import { getFeedSEOTags } from '../selectors/feed-seo-tags-selectors';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { waitForAction } from '../../common/store/wait-for-action';
import { SET_APP_SETTINGS } from '../../common/store/app-settings/app-settings-actions';
import { getLastFeaturedPost } from '../selectors/last-featured-post-selectors';
import { SET_BASIC_PARAMS } from '../../common/store/basic-params/basic-params-actions';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/store/categories/fetch-categories';

const createFeedPageRouter = (store, wixCodeApi) => async ({ params }, redirect, { preFetch, preFetchResult }) => {
  const page = parseInt(get(params, 'page', '1'), 10);
  const shouldFetchBanner = () =>
    getAppSettingsValue({ state: store.getState(), key: IS_BANNER_ENABLED_PATH }) &&
    !getLastFeaturedPost(store.getState());
  const getPageSize = () => getPageSizeFromSettings(store.getState(), { section: SECTION_HOMEPAGE });

  const actions = preFetchResult || [
    store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS, SET_BASIC_PARAMS],
        () =>
          fetchFeedPosts({
            page,
            pageSize: getPageSize(),
            excludeContent: isExcludePostContentSupported(store.getState(), SECTION_HOMEPAGE),
            section: SECTION_HOMEPAGE,
            preFetch,
          }),
        true,
      ),
    ),
    store.dispatch(
      waitForAction([SET_APP_SETTINGS], () => (shouldFetchBanner() ? fetchLastFeaturedPost(preFetch) : () => {}), true),
    ),
  ];

  if (preFetch) {
    return actions;
  }

  if (preFetchResult) {
    const [feedPosts, featuredPost] = await preFetchResult;
    await Promise.all([
      store.dispatch(completeFetchFeedPosts({ page, pageSize: getPageSize() }, feedPosts)),
      shouldFetchBanner() ? store.dispatch(completeFetchLastFeaturedPost(featuredPost)) : Promise.resolve(),
    ]);
  } else {
    await Promise.all(actions);
  }

  const showPagination = getShowPagination(store.getState(), SECTION_HOMEPAGE);
  const isValidPage = getIsValidPage(store.getState(), SECTION_HOMEPAGE);
  if (showPagination && !isValidPage) {
    redirect(ROUTE_404);
  }

  const state = store.getState();

  !isSSR(state) &&
    store.dispatch(pageOpened({ page: FEED_PAGE, isMobile: getIsMobile(state), isEditor: isEditor(state) }));

  if (isSite(state)) {
    !getFeedSEOTags(state) &&
      store.dispatch(
        setFeedSEOTags({
          title: wixCodeApi.seo.title,
          links: wixCodeApi.seo.links,
          metaTags: wixCodeApi.seo.metaTags,
        }),
      );

    const { title, links, metaTags } = generateFeedSEOTags({
      showPagination,
      state: store.getState(),
      page,
      t: translate,
    });

    wixCodeApi.seo.setLinks(links);
    wixCodeApi.seo.setMetaTags(metaTags);

    if (showPagination) {
      wixCodeApi.seo.setTitle(title);
    }
  }
};

const createSearchPageRouter = (store, wixCodeApi) => ({ params: { query } }) => {
  setMetaTagRobotsNoIndex(wixCodeApi);
  store.dispatch(clearSearchResults());
  if (query && query.length >= MIN_QUERY_LENGTH) {
    store.dispatch(setIsLoading('search', '', true));
    return !isSSR(store.getState()) && store.dispatch(search({ query: decodeQuery(query) }));
  }
  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: SEARCH_RESULTS }));
};

const createArchivePageRouter = (store, wixCodeApi) => ({ params }) => {
  setMetaTagRobotsNoIndex(wixCodeApi);

  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: ARCHIVE_PAGE }));

  const { year, month, pageIndex } = params;
  if (year && month) {
    return store.dispatch(fetchArchive(year, month, pageIndex));
  }
};

const createCategoryPageRouter = (store, wixCodeApi) => ({ params }, redirect, { preFetch, preFetchResult }) => {
  const page = parseInt(get(params, 'page', '1'), 10);
  const slug = resolveCategorySlug(params);
  const shouldExcludeContent = () => isExcludePostContentSupported(store.getState(), SECTION_CATEGORY);

  if (preFetch) {
    return store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS, SET_BASIC_PARAMS, FETCH_CATEGORIES_SUCCESS],
        () =>
          fetchCategoryPostsBySlug({
            slug,
            page,
            excludeContent: shouldExcludeContent(),
            preFetch,
          }),
        true,
      ),
    );
  }

  return store
    .dispatch(
      preFetchResult
        ? completeFetchCategoryPosts(
            {
              slug,
              page,
              pageSize: getPageSizeFromSettings(store.getState(), { section: SECTION_CATEGORY }),
              excludeContent: shouldExcludeContent(),
            },
            preFetchResult,
          )
        : fetchCategoryPostsBySlug({
            slug,
            page,
            excludeContent: shouldExcludeContent(),
            preFetch,
          }),
    )
    .then(() => {
      const state = store.getState();
      const category = getCategoryBySlug(state, slug);
      const showPagination = getShowPagination(state, SECTION_CATEGORY);
      const isValidPage = getIsValidPage(state, SECTION_CATEGORY);
      if (showPagination && !isValidPage) {
        redirect(ROUTE_404);
      }

      if (isSite(state)) {
        const appConfig = getAppConfig(state);
        const categorySEOTags = generateCategorySEOTags({
          appConfig,
          category,
          showPagination,
          state,
          page,
          t: translate,
        });
        wixCodeApi.seo.renderSEOTags(categorySEOTags);

        if (appConfig.isWP || getSchemaEnabled(state)) {
          const currentPagePosts = getPostsByCategoryIdAndPage(state, category._id, page);
          wixCodeApi.seo.setStructuredData([
            getCategorySchema(appConfig, category, currentPagePosts, getCategoriesMap(state)),
          ]);
        }
      }

      !isSSR(store.getState()) && store.dispatch(pageOpened({ page: CATEGORY_PAGE, category }));
    })
    .catch(error => {
      if (error.status === 404) {
        return redirect(ROUTE_404);
      }
      throw error;
    });
};

const createTagPageRouter = (store, wixCodeApi) => ({ params }, redirect) => {
  const state = store.getState();

  const isTagsPageSEOEnabled = isExperimentEnabled(state, EXPERIMENT_TAGS_PAGE_SEO);
  if (!isTagsPageSEOEnabled) {
    setMetaTagRobotsNoIndex(wixCodeApi);
  }

  let tag;

  const page = parseInt(get(params, 'page', '1'), 10);
  const tagSlug = get(params, 'tagSlug');

  return store
    .dispatch(fetchTag(tagSlug))
    .then(_tag => {
      tag = _tag;
      return store.dispatch(
        fetchTagPosts({
          tagId: _tag.id,
          page,
          excludeContent: isExcludePostContentSupported(store.getState(), SECTION_CATEGORY),
        }),
      );
    })
    .then(({ body: posts }) => {
      const showPagination = getShowPagination(store.getState(), SECTION_CATEGORY);
      const isValidPage = getIsValidPage(store.getState(), SECTION_CATEGORY);
      if (showPagination && !isValidPage) {
        return redirect(ROUTE_404);
      }

      if (isSite(state)) {
        const appConfig = getAppConfig(state);
        if (isTagsPageSEOEnabled) {
          const tagSEOTags = generateTagSEOTags({
            appConfig,
            tag,
            showPagination,
            state,
            page,
            posts,
            t: translate,
          });
          wixCodeApi.seo.renderSEOTags(tagSEOTags);
        } else {
          const { sectionUrl } = appConfig;
          const tagUrl = getTagUrl(sectionUrl, tag.slug);
          wixCodeApi.seo.setTitle(tag.label);
          wixCodeApi.seo.setLinks([{ href: tagUrl, rel: 'canonical' }]);
        }
      }

      !isSSR(store.getState()) && store.dispatch(pageOpened({ page: TAGS_PAGE }));
    })
    .catch(error => {
      if (error.status === 404) {
        return redirect(ROUTE_404);
      }
      throw error;
    });
};

export const createRouter = (store, _config, wixCodeApi) => {
  initI18N(controllerTranslations, getLanguage(store.getState()));
  const router = new Router();
  router.add(ROUTE_TAGS, createTagPageRouter(store, wixCodeApi));
  router.add(ROUTE_CATEGORIES, createCategoryPageRouter(store, wixCodeApi), { preFetch: true });
  router.add(ROUTE_CATEGORY, createCategoryPageRouter(store, wixCodeApi), { preFetch: true });
  router.add(ROUTE_SEARCH, createSearchPageRouter(store, wixCodeApi));
  router.add(ROUTE_ARCHIVE, createArchivePageRouter(store, wixCodeApi));
  router.add(ROUTE_HOME, createFeedPageRouter(store, wixCodeApi), { preFetch: true });
  router.add(ROUTE_404, createNotFoundPageRouter(store, wixCodeApi, FEED_PAGE_NOT_FOUND, ROUTE_404));
  router.add(ROUTE_LOGIN);
  router.fallback(ROUTE_404);
  return router;
};
