import {
  MODAL_TYPE_DELETE_COMMENT,
  MODAL_TYPE_DELETE_POST,
  MODAL_TYPE_DISCARD_POST_SETTINGS,
  MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS,
  MODAL_TYPE_DISCARD_POST_DRAFT,
  MODAL_TYPE_POST_EDITOR,
} from '@wix/communities-blog-client-common';
import { deleteCommentModalResolver } from '../../../comments/containers/delete-comment-modal/delete-comment-modal-resolver';
import { discardPostSettingsModalResolver } from '../../../post-settings/containers/discard-post-settings-modal/discard-post-settings-modal-resolver';
import { handlePostDelete } from '../../../common/services/handle-post-delete';
import { navigateWithinPostPage, navigateWithinBlog } from '../../../common/actions/navigate-within-blog';
import { discardPostDraft } from '../../../common/actions/discard-post-draft';

export const modalResolverMapByType = {
  [MODAL_TYPE_DELETE_COMMENT]: deleteCommentModalResolver,
  [MODAL_TYPE_DISCARD_POST_SETTINGS]: discardPostSettingsModalResolver,
  [MODAL_TYPE_DELETE_POST]: handlePostDelete({ permanently: false }),
  [MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS]: postSlug => dispatch => dispatch(navigateWithinPostPage(`/${postSlug}`)),
  [MODAL_TYPE_DISCARD_POST_DRAFT]: discardPostDraft,
  [MODAL_TYPE_POST_EDITOR]: post => dispatch => {
    if (post.slug) {
      dispatch(navigateWithinPostPage(`/${post.slug}`));
    } else {
      dispatch(navigateWithinBlog('/'));
    }
  },
};
