import { get } from 'lodash';
import { CLEAR_POSTS } from '../../actions/clear-posts';
import { SET_IS_LOADED, SET_IS_CATEGORY_LOADED } from './is-loaded-actions';
import { FETCH_COMMENTS_SUCCESS, FETCH_COMMENTS_FAILURE } from '../comments/fetch-comments';
import { FETCH_POST_SUCCESS, FETCH_POST_FAILURE } from '../../actions/fetch-post';
import {
  FETCH_LAST_FEATURED_POST_SUCCESS,
  FETCH_LAST_FEATURED_POST_FAILURE,
} from '../../actions/fetch-last-featured-post';
import { FETCH_CATEGORY_POSTS_SUCCESS, FETCH_CATEGORY_POSTS_FAILURE } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS, FETCH_TAG_POSTS_FAILURE } from '../../actions/fetch-tag-posts';
import { FETCH_FEED_POSTS_SUCCESS, FETCH_FEED_POSTS_FAILURE } from '../../actions/fetch-feed-posts';
import { FETCH_RECENT_POSTS_SUCCESS, FETCH_RECENT_POSTS_FAILURE } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_ARCHIVE_SUCCESS, FETCH_ARCHIVE_FAILURE } from '../../actions/fetch-archive';
import { SAVE_POST_DRAFT_SUCCESS } from '../../actions/save-post-draft-types';
import { CLEAN_POST_EDITOR_STATE, UPDATE_POST_EDITOR_POST } from '../post-editor/post-editor-actions';
import {
  FETCH_RELATED_POSTS_FAILURE,
  FETCH_RELATED_POSTS_SUCCESS,
} from '../../../post-page/actions/fetch-related-posts';

// eslint-disable-next-line complexity
export default function isLoaded(state = {}, action) {
  switch (action.type) {
    case SET_IS_LOADED:
      return setIsLoaded(state, action.payload);

    case SET_IS_CATEGORY_LOADED:
      return setIsLoaded(state, {
        entity: 'category',
        id: action.payload.categoryId,
        isLoaded: true,
      });

    case FETCH_POST_SUCCESS:
    case FETCH_POST_FAILURE:
      return setIsLoaded(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoaded: true,
      });

    case FETCH_LAST_FEATURED_POST_SUCCESS:
    case FETCH_LAST_FEATURED_POST_FAILURE:
      return setIsLoaded(state, {
        entity: 'last-featured-post',
        isLoaded: true,
      });

    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_FEED_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'feed-posts',
        isLoaded: true,
      });

    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'recent-posts',
        id: action.meta.postId,
        isLoaded: true,
      });

    case FETCH_RELATED_POSTS_SUCCESS:
    case FETCH_RELATED_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'related-posts',
        id: action.meta.postId,
        isLoaded: true,
      });

    case CLEAR_POSTS:
      return {
        ...setIsLoaded(state, {
          entity: 'feed-posts',
          isLoaded: false,
        }),
        category: {},
      };

    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'category',
        id: action.meta.categoryId,
        isLoaded: action.type === FETCH_CATEGORY_POSTS_SUCCESS,
      });

    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'tag',
        id: action.meta.tagId,
        isLoaded: action.type === FETCH_TAG_POSTS_SUCCESS,
      });

    case FETCH_COMMENTS_SUCCESS:
    case FETCH_COMMENTS_FAILURE:
      return setIsLoaded(state, {
        entity: 'comments',
        id: action.payload.postId,
        isLoaded: true,
      });

    case SAVE_POST_DRAFT_SUCCESS:
    case UPDATE_POST_EDITOR_POST:
      return setIsLoaded(state, {
        entity: 'postDraft',
        id: get(action, 'payload.draftKey', null),
        isLoaded: true,
      });

    case CLEAN_POST_EDITOR_STATE:
      return setIsLoaded(state, {
        entity: 'postDraft',
        id: get(action, 'payload', null),
        isLoaded: false,
      });

    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_ARCHIVE_FAILURE:
      return setIsLoaded(state, {
        entity: 'archive',
        isLoaded: true,
      });

    default:
      return state;
  }
}

function setIsLoaded(state, { entity, id = '', isLoaded }) {
  const entityState = id
    ? {
        ...state[entity],
        [id]: isLoaded,
      }
    : isLoaded;

  return {
    ...state,
    [entity]: entityState,
  };
}
