import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { FETCH_SEARCH_SUCCESS } from '../../../search/actions/fetch-search-types';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from '../../actions/subscribe-types';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from '../../actions/unsubscribe-types';

export default function postSubscription(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_POST_SUCCESS:
      return post(state, payload);
    case FETCH_POST_METADATA_SUCCESS:
      return post(state, payload);
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
      return posts(state, payload);
    case FETCH_SEARCH_SUCCESS:
      return posts(state, payload.posts);
    case SUBSCRIBE_REQUEST:
    case UNSUBSCRIBE_FAILURE:
      return subscribe(state, payload._id, true);
    case SUBSCRIBE_FAILURE:
    case UNSUBSCRIBE_REQUEST:
      return subscribe(state, payload._id, false);
    default:
      return state;
  }
}

function post(state, post) {
  return { ...state, [post._id]: { isSubscribed: post.isSubscribed } };
}

function posts(state, posts) {
  return {
    ...state,
    ...posts.reduce((result, post) => {
      result[post._id] = { isSubscribed: post.isSubscribed };
      return result;
    }, {}),
  };
}

function subscribe(state, _id, value) {
  const post = state[_id];
  if (!post) {
    return state;
  }
  return { ...state, [_id]: { isSubscribed: value } };
}
