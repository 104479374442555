import { createAction, ARCHIVE_POSTS_PER_PAGE, urijs } from '@wix/communities-blog-client-common';
import { normalizePostEntities } from '../services/post-utils';
import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';

export const FETCH_ARCHIVE_REQUEST = 'archive/FETCH_REQUEST';
export const FETCH_ARCHIVE_SUCCESS = 'archive/FETCH_SUCCESS';
export const FETCH_ARCHIVE_FAILURE = 'archive/FETCH_FAILURE';

export const fetchArchiveRequest = createAction(FETCH_ARCHIVE_REQUEST);
export const fetchArchiveFailure = createAction(FETCH_ARCHIVE_FAILURE);
export const fetchArchiveSuccess = createAction(
  FETCH_ARCHIVE_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);

export default function fetchArchive(year, month, page = 1) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchArchiveRequest());
    const path = `/_api/posts/archive/${year}/${month}`;
    const params = buildPaginationRequestParams(page, ARCHIVE_POSTS_PER_PAGE);
    // HACK: const promise = request.metered('/_api/posts/archive')(uri(path).query({ ...params }), { parseHeaders: true });
    const promise = request(urijs(path).query({ ...params }), { parseHeaders: true });

    return promise
      .then(response => {
        return dispatch(
          fetchArchiveSuccess(normalizePostEntities(response.body, getState()), {
            entityCount: getTotalResults(response.headers),
            pageSize: ARCHIVE_POSTS_PER_PAGE,
            page,
          }),
        );
      })
      .catch(() => dispatch(fetchArchiveFailure()))
      .then(() => promise);
  };
}
