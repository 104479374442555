import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId, getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { setLocale } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { getInstance } from '../../common/controller/helpers';

export {
  initializeActions,
  initializePromisifiedActions,
  initUserDependentStoreBaseData,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  sectionUrl,
  bundleName,
  fedopsAppName,
  translationsName,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  const metaSiteId = getMetaSiteId(store.getState());

  await Promise.all([
    store.dispatch(fetchExperiments(metaSiteId)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(fetchTranslations(language, translationsName)),
    store.dispatch(setLocale(language)),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
  ]);

  await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
  await store.dispatch(setAppConfig({ sectionUrl, bundleName, fedopsAppName }));
}
