import { WS_EMIT, WS_JOIN, WS_LEAVE } from '../../actions/ws-actions';
import { isAuthenticated } from '../../store/auth/auth-selectors';
import createDuplexerConnection from './create-duplexer-connection';

export default function createDuplexerMiddleware({
  getInstance,
  duplexerUrl,
  blogApiBaseUrl,
  staticsBaseUrl,
  instanceId,
  eventHandlers = {},
  captureMessage = () => {},
}) {
  const channels = {};
  const history = [];
  let connection;

  return store => next => action => {
    const actionHandler = actionHandlers[action.type];

    if (actionHandler) {
      if (!connection) {
        connection = createDuplexerConnection({
          duplexerUrl,
          blogApiBaseUrl,
          staticsBaseUrl,
          instance: getInstance(),
        });
      }

      history.push(`${action.type} ${action.payload.channel}`);

      const channelName = getChannelName(action.payload.channel, instanceId);
      return actionHandler({
        action,
        store,
        channelName,
        channels,
        connection,
        eventHandlers,
        captureMessage,
        history,
      });
    }

    return next(action);
  };
}

const actionHandlers = {
  [WS_JOIN]: ({ action, channelName, store, connection, eventHandlers, channels }) => {
    const channel = connection.subscribe(channelName, {
      forceTokenRequest: Boolean(action.payload.hasClientEvents && isAuthenticated(store.getState())),
    });
    channels[channelName] = channel;

    Object.keys(eventHandlers).forEach(event => {
      channel.on(event, (data, { user } = {}) => {
        store.dispatch(eventHandlers[event](data, user));
      });
    });

    return channel;
  },
  [WS_LEAVE]: ({ channelName, connection, channels, captureMessage, history }) => {
    if (channels[channelName]) {
      connection.unsubscribe(channelName);
      delete channels[channelName];
    } else {
      captureMessage('Cannot leave non-existent channel', { channelName, history });
    }
  },
  [WS_EMIT]: ({ action, channelName, channels, captureMessage, history }) => {
    const { eventName, message } = action.payload;

    if (channels[channelName]) {
      return channels[channelName].send(eventName, message);
    } else {
      captureMessage('Cannot emit to non-existent channel', { channelName, history });
    }
  },
};

function getChannelName(entity, instanceId) {
  return `instance-${instanceId}__${entity}`;
}
